import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import 'element-ui/lib/theme-chalk/index.css';
import "animate.css/animate.min.css";
// 分页组件
import Pagination from "@/components/Pagination";

import '@/icons'
import "./permission"; // permission control
Vue.use(ElementUI);
import './assets/css/element-variables.scss'
Vue.use(VueAwesomeSwiper);

//注册到vue原型上
import axios from "./utils/request"; // axios
Vue.prototype.$API = axios;

Vue.config.productionTip = false

Vue.component('Pagination', Pagination)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 修改标题的函数
function changeTitle(newTitle) {
  document.title = newTitle; // 修改页面标题
}

// 在应用初始化后，调用修改标题的函数
changeTitle('赢拓教育科技集团-互联网人才服务商');