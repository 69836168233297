import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
	{
	  path: '/talent',
	  name: 'talent',
	  component: () => import('@/views/talent.vue')
	},
	{
	  path: '/findmore',
	  name: 'findmore',
	  component: () => import('@/views/findmore.vue')
	},
	{
	  path: '/about',
	  name: 'about',
	  component: () => import('@/views/about.vue')
	},
	{
	  path: '/serve',
	  name: 'serve',
	  component: () => import('@/views/serve.vue')
	},
	{
	  path: '/skillTesting',
	  name: 'skillTesting',
	  component: () => import('@/views/skillTesting.vue')
	},
	{
	  path: '/questionBank',
	  name: 'questionBank',
	  component: () => import('@/views/questionBank.vue')
	},
	{
	  path: '/onlinePractice',
	  name: 'onlinePractice',
	  component: () => import('@/views/onlinePractice.vue')
	},
	{
	  path: '/practice',
	  name: 'practice',
	  component: () => import('@/views/practice.vue')
	},
	{
	  path: '/questionTypeSet',
	  name: 'questionTypeSet',
	  component: () => import('@/views/questionTypeSet.vue')
	},
	{
	  path: '/practiceTest',
	  name: 'practiceTest',
	  component: () => import('@/views/practiceTest.vue')
	},
	{
	  path: '/practiceTestSet',
	  name: 'practiceTestSet',
	  component: () => import('@/views/practiceTestSet.vue')
	},
	{
	  path: '/testResult',
	  name: 'testResult',
	  component: () => import('@/views/testResult.vue')
	},
	{
	  path: '/mistakes',
	  name: 'mistakes',
	  component: () => import('@/views/mistakes.vue')
	},
	{
	  path: '/searchQuestion',
	  name: 'searchQuestion',
	  component: () => import('@/views/searchQuestion.vue')
	},
	{
	  path: '/questionDetail',
	  name: 'questionDetail',
	  component: () => import('@/views/questionDetail.vue')
	},
	{
	  path: '/notice',
	  name: 'notice',
	  component: () => import('@/views/notice.vue')
	},
	{
	  path: '/InquiryArea',
	  name: 'InquiryArea',
	  component: () => import('@/views/InquiryArea.vue')
	},
	{
	  path: '/technology',
	  name: 'technology',
	  component: () => import('@/views/technology.vue')
	},
	{
	  path: '/Course',
	  name: 'Course',
	  component: () => import('@/views/Course.vue')
	},
	{
	  path: '/culture',
	  name: 'culture',
	  component: () => import('@/views/culture.vue')
	},
	{
	  path: '/cooperate',
	  name: 'cooperate',
	  component: () => import('@/views/cooperate.vue')
	},
	{
	  path: '/Contactus',
	  name: 'Contactus',
	  component: () => import('@/views/Contactus.vue')
	},
	{
	  path: '/new',
	  name: 'new',
	  component: () => import('@/views/new.vue')
	},
	{
	  path: '/new1',
	  name: 'new1',
	  component: () => import('@/views/new1.vue')
	},
	{
	  path: '/new2',
	  name: 'new2',
	  component: () => import('@/views/new2.vue')
	},
	{
	  path: '/team',
	  name: 'team',
	  component: () => import('@/views/team.vue')
	},
	{
	  path: '/newsid',
	  name: 'newsid',
	  component: () => import('@/views/newsid.vue')
	},
	{
	  path: '/login',
	  name: 'login',
	  component: () => import('@/views/login.vue')
	}
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
