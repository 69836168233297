// import { login, logout, getInfo } from '@/api/login'
import axios from 'axios'; // 导入axios库
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    userId: '',
    deptName: '',
    deptId: '',
    avatar: '',
    roles: [],
    permissions: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_DEPTNAME: (state, deptName) => {
      state.deptName = deptName
    },
    SET_DEPTID: (state, deptId) => {
      state.deptId = deptId
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {

        const url = "https://se.51shizhan.cn/api/loginPortal";
        axios.post(url,{
          username,
          password,
          code,
          uuid
        }).then(res => {
          setToken(res.data.token)
          commit('SET_TOKEN', res.data.token)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        const url = "https://se.51shizhan.cn/api/getInfo";
        axios.get(url,{
          headers: {'Authorization': `Bearer ${state.token}`}
        }).then(res => {
          console.log(res.data.user)
          const user = res.data.user
          const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/img/profile.jpg") : '/api' + user.avatar;
          if (res.data.roles && res.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.data.roles)
            commit('SET_PERMISSIONS', res.data.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_NAME', user.userName)
          commit('SET_USERID', user.userId)
          // commit('SET_DEPTNAME', user.dept.deptName)
          commit('SET_DEPTID', user.deptId)
          commit('SET_AVATAR', avatar)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        const url = "https://se.51shizhan.cn/api/logout";
        const token = state.token
        axios.post(url,{
          token
        }).then(res => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
